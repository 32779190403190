import { buffers, eventChannel, END } from 'redux-saga';
import get from 'lodash/get';
import { saveFile } from 'shared/utils/file';

import { Auth } from 'aws-amplify';
import axios from 'axios';

export const isImage = (url) => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
};
export const isVideo = (url) => {
  return url.match(/\.(mp4|mov|avi)$/) !== null;
};
export const isAudio = (url) => {
  return url.match(/\.(mp3|mp3a)$/) !== null;
};

const getSignedUrlFromBackend = async (bucket_name, key) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const suffix = "files/view";
  const request_params = `?bucket_name=${bucket_name}&key=${key}`;

  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getIdToken().getJwtToken();
  const response = await fetch(`${base_url}${suffix}${request_params}`, {
    method: "GET",
    headers: {
      "authorization": `${accessToken}`,
      "Accept": "application/json, text/plain, */*",
    }
  });
  if(!response.ok) {
    console.error("Something went wrong while fetching campaign files from backend");
    return [];
  }
  const json = await response.json();
  return json["url"];
}

export const getSignedUrl = (params) => {
  console.log(params)
  if (!params.Key || params.Key.length < 1) {
    return '';
  }
//  const url = getSignedUrlFromBackend(params.Bucket, params.Key)
  //const url = s3Files.getSignedUrl('getObject', params);
  return "";
  return url;
};

export const downloadS3File = (params) => {
  if (!params.Key || params.Key.length < 1) {
    return '';
  }
  return '';
  // s3Files.getObject(params, (err, data) => {
  //   if (err || !data.Body) {
  //     console.log('error');
  //     return;
  //   }
  //   saveFile(data.Body, params.Key.split(/[\\/]/).pop(), data.ContentType);
  // });
};

export const getUrlWithoutHost = (originalUrl) => {
  if (!originalUrl) {
    return '';
  }
  const url = originalUrl
    .replace(`${process.env.REACT_APP_AWS_DISCUSSION_BUCKET_URL}/`, '')
    .replace(`${process.env.REACT_APP_AWS_DISCUSSION_UPLOAD_BUCKET_URL}/`, '');
  return url;
};

const retrieveCampaignFilesFromBackend = async (bucket_name, campaignId) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const suffix = "files/campaign";
  const request_params = `?bucket_name=${bucket_name}&campaign_id=${campaignId}`;

  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getIdToken().getJwtToken();

  const response = await fetch(`${base_url}${suffix}${request_params}`, {
    method: "GET",
    headers: {
      "authorization": `${accessToken}`,
      "Accept": "application/json, text/plain, */*",
    }
  });
  if(!response.ok) {
    console.error("Something went wrong while fetching campaign files from backend");
    return [];
  }
  const json = await response.json();
  return json["files"]
}

export const fetchCampaignFilesFromS3 = async (campaignName) => {
  const bucket_name = process.env.REACT_APP_AWS_BUCKET;

  return await retrieveCampaignFilesFromBackend(bucket_name, campaignName);
};

export const retrieveUploadUrl = async (bucket_name, key, metadata, mediatype) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const suffix = "files/upload"

  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getIdToken().getJwtToken();

  const response = await fetch(`${base_url}${suffix}`, {
    method: "POST",
    body: JSON.stringify(
      {
        'bucketName': bucket_name,
        'key': key,
        'metadata': metadata,
        'contentType': mediatype
      }
    ),
    headers: {
      "authorization": `${accessToken}`,
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  });

  if (response.status != 200) {
    throw new Error("Something went wrong");
  }
  const body_json = await response.json()
  return body_json['url'];
}


export const uploadFileToS3 = async (config) => {
  return eventChannel((emitter) => {
    const { Bucket: bucketName, Key: key, Metadata: metadata } = config;
    const file = config.Body;
    const mediatype = file.type;
    let campaignId;
    if(metadata) {
      campaignId = metadata["x-acr-campaign-id"]
    }

    retrieveUploadUrl(bucketName, key, metadata, mediatype).then(signedUrl => {
      const axiosConfig = {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          emitter({ progress: percentCompleted });
        },
      };

      axios.put(signedUrl, file, axiosConfig)
        .then((uploadResponse) => {
          if (uploadResponse.status !== 200) {
            emitter({ error: new Error('Upload failed') });
            emitter(END);
            throw new Error("Upload failed with status: " + uploadResponse.status);
          }
          return uploadResponse;
        })
        .then((response) => {
          response["key"] = key;
          emitter({ success: true, data: { response, campaignId, Location: `https://thx-discussions-media.s3.eu-west-2.amazonaws.com/${key}` } });
          emitter(END);
        })
        .catch((error) => {
          emitter({ error });
          emitter(END);
        });
    }).catch(error => {
      emitter({ error });
      emitter(END);
    });

    return () => {
      // Cancel any ongoing requests if needed
    };
  }, buffers.sliding(2));
}; 
