import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { CampaignForm, ContentLoader } from 'components'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useDetails } from 'hooks'
import {
    CampaignResponse,
    LocationResponse,
    useCampaignManagement,
    useClientManagement,
} from 'modules/campaignM'
import { sortByLabel } from 'helpers'
import { routes } from 'shared/constants/routes'
import { Misc } from './Misc'
import L from 'leaflet';

export const CampaignDetails = () => {
    const [clients, setClients] = useState<{ value: string; label: string }[]>([])
    const [locations, setLocations] = useState<LocationResponse[]>([])
    const { createCampaign, fetchCampaign, updateCampaign } = useCampaignManagement()
    const { fetchClients } = useClientManagement()

    const { data, onSave, requestStatus } = useDetails<CampaignResponse>({
        fetch: fetchCampaign,
        create: createCampaign,
        update: updateCampaign,
        redirectTo: routes.campaigns.default.path,
    })

    useEffect(() => {
        const fetch = async () => {
            const { content } = await fetchClients({ size: 100000 })
            setClients(content.map(({ name }) => ({ value: name, label: name })).sort(sortByLabel))
        }
        fetch()
    }, [])

    useEffect(() => {
        if (data && data.locations) {
            setLocations(data.locations)
        }
    }, [data && data.locations])


    const handleLocationUpdate = (location: LocationResponse) => {
        const newLocations = locations.map(l => l.id === location.id ? location : l)
        setLocations(newLocations)
    }

    const handleAddLocation = (location: LocationResponse) => {
        setLocations([...locations, location])
    }

    const icon = L.icon({ iconUrl: "/images/marker-icon.png" });


    const map = data && data.id ? (
        <MapContainer 
            center={[52.325268, 5.627611]}
            zoom={7} 
            style={{ height: '100%', width: '100%' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {locations.map(location => (
                <Marker 
                    key={location.id} 
                    position={[location.latitude, location.longitude]}
                    icon={icon}
                >
                    
                </Marker>
            ))}
        </MapContainer>
    ) : null;

    const misc = data && data.id ? <Misc data={data} locations={locations} setLocations={setLocations} /> : null

    return (
        <ContentLoader status={requestStatus}>
            <CampaignForm
                clients={clients}
                defaultValues={data}
                map={map}
                misc={misc}
                onSubmit={onSave}
            />
        </ContentLoader>
    )
}
