import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import { Field, reduxForm, getFormValues } from 'redux-form';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import Button from 'shared/components/Button';
import { routes } from 'shared/constants/routes';
import { orderAlphabetically } from 'shared/utils/fields';
import {
  Thead,
  Tbody,
  Table,
  Th,
  Tr,
  Td,
  TableFilters,
  LastTh,
  LastTd,
} from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import { fetchCampaigns, fetchClients } from 'modules/Campaign/reducers/list';
import TextField from 'shared/components/TextField';
import AutocompleteField from 'shared/components/AutocompleteFieldNew';
import Pagination from 'shared/components/Pagination';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/music-note.svg';
import styles from './styles.module.scss';
import * as types from '../../types/CampaignListTypes';

const FORM_NAME = 'campaign/LIST';
class List extends PureComponent<types.CampaignListProps, types.CampaignListState> {
  state: types.CampaignListState = {
    client: undefined,
    perPage: 15,
    sort: 'id,desc',
    term: undefined,
  };

  getCampaignList = () => {
    this.props.fetchClients();
    const { country, ...params } = qs.parse(this.props.location.search) || {};
    this.search(params);
    if (params.client) {
      this.props.change('client', params.client);
    }
  };

  componentDidMount() {
    this.getCampaignList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCountry !== this.props.selectedCountry) {
      this.getCampaignList();
    }
  }

  changeQuery = value => {
    this.setState({ client: value });
    const { history } = this.props;
    const search = { ...qs.parse(history.location.search), page: 0, client: value };
    this.props.change('client', value);
    history.push({
      pathname: routes.campaigns.default.path,
      search: `?${qs.stringify(search)}`,
    });
    this.search(search);
  };

  goToCreate = () => {
    const { history } = this.props;
    history.push({
      pathname: routes.campaigns.create.path,
      search: history.location.search,
    });
  };
  goToManage = id => () => {
    const { history } = this.props;
    history.push({
      pathname: routes.campaigns.edit(id).path,
      search: history.location.search,
    });
  };

  search = props => {
    const search = {
      page: this.props.data.number,
      size: this.state.perPage,
      client: this.state.client,
      sort: this.state.sort,
      term: this.state.term,
      ...props,
    };
    this.props.fetchCampaigns({
      search,
    });
  };
  onSearchText = debounce(e => {
    this.setState({ term: e.target.value });
    this.search({ term: e.target.value, page: 0 });
  }, 300);
  onPageChange = page => {
    this.search({ page });
  };
  onPerPageChange = perPage => {
    this.search({ size: perPage });
    this.setState({ perPage });
  };
  getCurrentSortType = () => {
    return this.state.sort.split(',')[1];
  };
  onChangeSort = colName => {
    const currentSort = this.getCurrentSortType();
    let sortType = currentSort;
    if (this.isActiveColSort(colName)) {
      sortType = currentSort === 'desc' ? 'asc' : 'desc';
    }

    const sort = `${colName},${sortType}`;
    this.setState({
      sort,
    });
    this.search({ sort });
  };
  isActiveColSort = colName => {
    return colName === this.state.sort.split(',')[0];
  };
  render() {
    const { data, states, clients } = this.props;
    const columns = [
      { label: '#', colName: 'id', sortable: true },
      { label: 'Name', colName: 'name', sortable: true },
      { label: 'Client', colName: 'client_name', sortable: true },
      { label: 'From', colName: 'date_from', sortable: true },
      { label: 'To', colName: 'date_to', sortable: true },
      { label: 'Status', colName: 'status', sortable: true },
      { label: '', sortable: false },
    ];

    return (
      <div>
        <ListHeading
          title="Campaigns"
          subTitle="for client:"
          right={
            <div className="d-flex align-items-center">
              <div className={styles.ListHeading}>
                <Field
                  component={AutocompleteField}
                  name="client"
                  placeholder="Select client"
                  dataSource={clients}
                  noMargin
                  onSelect={this.changeQuery}
                />
              </div>
              <Field
                onChange={this.onSearchText}
                component={TextField}
                name="search"
                placeholder="Type to search"
                noMargin
              />
            </div>
          }
        >
          <Button type="primary" onClick={this.goToCreate}>
            Add new
          </Button>
        </ListHeading>
        <AppContent>
          <TableFilters>
            <div className="col-md-4 col-lg-3"></div>
          </TableFilters>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={states.isLoading}>
                <Thead>
                  <Tr>
                    {columns.map((col, index) => {
                      if (index === columns.length - 1) {
                        return (
                          <LastTh
                            key={`col-${col.label}`}
                            onClick={
                              col.sortable ? () => this.onChangeSort(col.colName) : null
                            }
                            currentSortType={this.getCurrentSortType()}
                            active={this.isActiveColSort(col.colName)}
                            sortable={col.sortable}
                            short={true}
                          >
                            {col.label}
                          </LastTh>
                        );
                      } else {
                        return (
                          <Th
                            key={`col-${col.label}`}
                            onClick={
                              col.sortable ? () => this.onChangeSort(col.colName) : null
                            }
                            currentSortType={this.getCurrentSortType()}
                            active={this.isActiveColSort(col.colName)}
                            sortable={col.sortable}
                            short={index === 0}
                          >
                            {col.label}
                          </Th>
                        );
                      }
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {map(data.content, campaign => (
                    <Tr key={campaign.id} onClick={this.goToManage(campaign.id)}>
                      <Td>                      
                      <a href={`/app/campaigns/edit/${campaign.id}`} rel="noopener noreferrer" className={styles.tableLink}>
                      {campaign.id}
                        </a>
                      </Td>
                      <Td>
                        <a href={`/app/campaigns/edit/${campaign.id}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {campaign.name}
                        </a>
                      </Td>
                      <Td>
                        <a href={`/app/campaigns/edit/${campaign.id}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {get(campaign, 'clientName')}
                        </a>
                      </Td>
                      <Td>
                        <a href={`/app/campaigns/edit/${campaign.id}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {get(campaign, 'dateFrom')}
                        </a>
                      </Td>
                      <Td>
                        <a href={`/app/campaigns/edit/${campaign.id}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {get(campaign, 'dateTo')}
                        </a>
                      </Td>
                      <Td>
                        <a href={`/app/campaigns/edit/${campaign.id}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {get(campaign, 'status') === 'OPEN' ? 'Open' : 'Closed'}
                        </a>
                      </Td>
                      <LastTd>
                        <div className={styles.icons}>
                          {campaign.containsLocations && (
                            <MapIcon className={styles.svgIcon} />
                          )}
                          {campaign.containsMedia && (
                            <NoteIcon className={styles.svgIcon} />
                          )}
                        </div>
                      </LastTd>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                onPageChange={this.onPageChange}
                pages={data.totalPages - 1}
                currentPage={data.number}
                visibleOffset={1}
                perPage={this.state.perPage}
                onPerPageChange={this.onPerPageChange}
              />
            </div>
          </div>
        </AppContent>
      </div>
    );
  }
}

const mapStateToProps = ({ campaigns, ...state }) => ({
  data: campaigns.list.data,
  states: campaigns.list.states,
  formValues: getFormValues(FORM_NAME)(state),
  selectedCountry: state.app.country,
  clients: uniqBy(
    [
      { value: '', label: 'Select client' },
      ...orderAlphabetically(
        map(campaigns.list.clients.data, ({ name }) => ({
          value: name,
          label: name,
        })),
      ),
    ],
    'value',
  ),
  initialValues: {},
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchCampaigns, fetchClients }, dispatch);

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME }),
)(List);
