import axios from 'axios';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  }
});

export const searchPexelsByQuery = (query) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const suffix = "pexels/search";
  const request_params = `?query=${query}`;

  let a = localStorage.getItem("auth");
  a = JSON.parse(a);
  const accessToken = a.idToken.jwtToken;

  return instance.get(`${base_url}${suffix}${request_params}`, {
    headers: {
      "authorization": `${accessToken}`,
      "Accept": "application/json, text/plain",
    }
  })
}

export const searchPexels = (query) => {
  const encoded_search_query = btoa(query);
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const suffix = "pexels/search";
  const request_params = `?query=${encoded_search_query}`;

  let a = localStorage.getItem("auth");
  a = JSON.parse(a);
  const accessToken = a.idToken.jwtToken 

  return instance.get(`${base_url}${suffix}${request_params}`, {
    headers: {
      "authorization": `${accessToken}`,
      "Accept": "application/json, text/plain",
    }
  })
}
