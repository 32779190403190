import React from 'react';
import { UserInfoForm } from 'components/UserInfoForm';
import { useDetails } from 'hooks';
import { useUserManagement } from 'modules/user';
import { routes } from 'shared/constants/routes';
import { ContentLoader } from 'components';
import { getReferrals } from 'modules/user/api';

export const UserDetails = () => {
  const { fetchUser, updateUser } = useUserManagement();
  const { data, onSave, requestStatus } = useDetails<any>({
    fetch: fetchUser,
    create: updateUser,
    update: updateUser,
    redirectTo: routes.users.default.path,
  });

  const defaultValues = data
  ? { ...data, referrals: data.referrals || [], referralAwarded: data?.referralAwarded ? "Yes" : "No" }
  : { pushNoteEnabled: true, referrals: [] };




  return (
    <ContentLoader status={requestStatus}>
      <UserInfoForm defaultValues={defaultValues} onSubmit={onSave} />
    </ContentLoader>
  );
};
