import React from 'react';
import MuiBox from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import {
  AutocompleteField,
  Button,
  DateField,
  FieldLabel,
  Form,
  FormHeader,
  FormProps,
  Page,
  TextHeaderField,
} from 'components';
import { schema } from './validation';
import { CampaignFormData } from './types';

interface AutocompleteOption {
  label: string;
  value: string;
}

interface CampaignFormProps extends FormProps<CampaignFormData> {
  clients: AutocompleteOption[];
  map: React.ReactNode;
  misc: React.ReactNode;
}

export const CampaignForm = ({
  clients,
  defaultValues,
  onSubmit,
  map,
  misc,
}: CampaignFormProps) => (
  <MuiBox pt="140px">
    <MuiGrid container spacing={0}>
      <MuiGrid item xs={6}>
        <Form<CampaignFormData>
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          schema={schema}
        >
          {({ isSubmitDisabled, isSubmitting }) => (
            <MuiBox pl="55px" pt="10px" pr="25px">
              <MuiBox
                position="fixed"
                top="50px"
                left="143px"
                right="0"
                height="140px"
                zIndex={4}
              >
                <FormHeader
                  field={
                    <TextHeaderField name="name" placeholder="Enter campaign title" />
                  }
                  cta={
                    <Button disabled={isSubmitDisabled} loading={isSubmitting}>
                      Save
                    </Button>
                  }
                />
              </MuiBox>
              <MuiTypography variant="h2">Campaign details</MuiTypography>
              <AutocompleteField
                creatable
                label="Client"
                name="clientName"
                options={clients}
                required
              />
              <FieldLabel label="Period" required />
              <MuiGrid container spacing={3}>
                <MuiGrid item xs>
                  <DateField name="dateFrom" required />
                </MuiGrid>
                <MuiGrid item xs>
                  <DateField name="dateTo" required />
                </MuiGrid>
              </MuiGrid>
            </MuiBox>
          )}
        </Form>
        <MuiBox pl="55px" pr="25px" pb="55px">
          {misc}
        </MuiBox>
      </MuiGrid>
      <MuiGrid item xs={6} >
        <MuiBox position="sticky" top="190px" pl="25px" style={{ height: "calc(100vh - 10rem)"}}>
          {map}
        </MuiBox>
      </MuiGrid>
    </MuiGrid>
  </MuiBox>
);
